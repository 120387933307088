import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { font, colors } from '../consts/style';

const Wrapper = styled.div`
  text-align: center;
  border-bottom: 0.1rem solid ${colors.gold};
  h1 {
    ${font.h1};
    color: ${colors.gold};
    margin-bottom: 0.8rem;
  }
`;

export default function Heading({ title }) {
  return (
    <Wrapper>
      <h1>{title}</h1>
    </Wrapper>
  );
}

Heading.propTypes = {
  title: PropTypes.string.isRequired,
};
