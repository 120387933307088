import React, { Fragment } from 'react';
import styled from 'styled-components';
import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';

import SEO from '../components/SEO';
import Heading from '../components/Heading';
import TourDate from '../components/TourDate';
import { Container, Inner } from '../components/Elements';
// import Audio from '../components/Audio';

import { colors, font } from '../consts/style';
import mq from '../style/mq';

const indexQuery = graphql`
  {
    home: datoCmsHomepage {
      heroCtaLabel
      heroCtaHeadline
      heroCtaText
      heroCtaButtonText
      heroCtaButtonLink
      heroImage {
        fluid(maxWidth: 1800, imgixParams: { fm: "jpg", auto: "compress" }) {
          ...GatsbyDatoCmsFluid_noBase64
        }
      }
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      featuredAlbum {
        name
        image {
          url
          fluid(maxWidth: 1000, imgixParams: { fm: "jpg", auto: "compress" }) {
            ...GatsbyDatoCmsFluid_noBase64
          }
        }
        listenLink
        buttonTextOverride
        optionalText
      }
      additionalAlbums {
        id
        name
        image {
          fluid(maxWidth: 1000, imgixParams: { fm: "jpg", auto: "compress" }) {
            ...GatsbyDatoCmsFluid_noBase64
          }
        }
        listenLink
      }
      liveDates {
        id
        date
        throughDate
        venue
        city
        ticketLink
        vipLink
        soldOut
      }
      tracks {
        ... on DatoCmsTrack {
          id
          file {
            url
          }
        }
      }
    }
    hourglass: file(relativePath: { eq: "hourglass.png" }) {
      childImageSharp {
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
  }
`;

const HeroWrapper = styled.div`
  height: 100vh;
  background: ${colors.light};
  position: relative;
  ${mq.mobile`
    height: calc(100vh - 4rem);
  `}
`;

const HeroImg = styled(Img)`
  position: absolute !important;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 0;
  height: calc(100vh);

  ${mq.mobile`
    height: calc(100vh - 4rem);
  `}

  & > img {
    object-fit: 'cover' !important;
    object-position: '50% 50%' !important;
  }
  &::before {
    content: '';
    background: rgba(0, 0, 0, 0.25);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    z-index: 1;
  }
`;

// const HeroAlbum = styled.img`
//   position: relative !important;
//   width: 20rem;
//   margin-bottom: 0.8rem;
// `;

const HourglassWrapper = styled.div`
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  position: relative;
  ${Container} {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

const Hourglass = styled(Img)`
  width: 30rem;
  ${mq.mobile`
  width: 20rem;
  `}
`;

const HeroInnerContainer = styled(Container)`
  position: relative;
  z-index: 10;
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: flex-start;
  align-items: flex-end;
  div {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    color: ${colors.light};
    width: 36rem;
    margin-bottom: 10rem;
    margin-left: 4rem;
    ${mq.tablet`
    margin-bottom: 8rem;
    margin-left: 2rem;
    `}
    ${mq.mobile`
    width: 100% !important;
    margin-bottom: 6rem;
    margin-left: 0;
    `}
    a:hover {
      text-decoration: none;
    }
    h2 {
      ${font.h2};
    }
    h3 {
      font-weight: normal;
      margin-bottom: 0;
      margin-top: 0.4rem;
    }
    button {
      margin-top: 1.2rem;
      ${font.buttonBlack};
      &:hover {
        text-decoration: none;
      }
    }
  }
`;

const Album = styled(Img)`
  margin-top: 3.2rem;
`;
const AlbumWrapper = styled.div``;

const FeaturedWrapper = styled(Container)`
  margin-bottom: 8rem;
  ${mq.mobile`
  min-height: 0 !important;
  margin-bottom: 6rem;
`}
  ${Inner} {
    width: 140rem;
    max-width: 100%;
    display: flex;
    justify-content: flex-end;

    ${AlbumWrapper} {
      width: 40%;
      max-width: 100%;
      margin-right: 3.2rem;
      @media screen and (max-width: 960px) {
        width: 100%;
        margin-right: 0;
      }
    }
    ${Album} {
      width: 100%;
    }
    @media screen and (max-width: 960px) {
      justify-content: center !important;
      flex-wrap: wrap;
      .content {
        width: 100% !important;
      }
      ${Album} {
        margin-right: 0;
        margin-bottom: 1.2rem;
      }
    }
    .content {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      width: 27%;
      text-align: center;
      h3 {
        font-weight: normal;
        margin-bottom: 0;
        margin-top: 0.4rem;
        color: ${colors.gold};
      }
      h2 {
        ${font.h3};
        color: ${colors.gold};
      }
      button {
        margin-top: 1.2rem;
        ${font.button};
      }
    }
  }
`;

const AdditionalWrapper = styled(Container)`
  margin-bottom: 8rem;
  ${Inner} {
    width: 140rem;
    max-width: 100%;
    display: flex;

    @media screen and (max-width: 960px) {
      flex-wrap: wrap;
      .add {
        margin-bottom: 6rem !important;
        width: 48% !important;
        margin-right: 2%;
        &:nth-child(2n) {
          margin-right: 0% !important;
        }
      }
    }

    ${mq.mobile`
    .add {
      width: 100% !important;
     
      margin-right: 0%;
      &:nth-child(2n) {
        margin-right: 0% !important;
      }
    }
  `}
    .add {
      width: 32%;
      margin-right: 3%;
      margin-bottom: 2.4rem;
      text-align: center;
      display: flex;
      flex-direction: column;
      align-items: center;
      &:nth-child(3n) {
        margin-right: 0;
      }
    }
    h3 {
      ${font.h3};
      color: ${colors.gold};
    }
    button {
      margin-top: 1.2rem;
      ${font.button};
    }
    ${Album} {
      margin-bottom: 1.6rem;
      width: 100%;
    }
  }
`;

const DateWrapper = styled(Container)`
  align-items: center;
  display: flex;
  min-height: 100vh;
  ${Inner} {
    max-width: 100%;
  }
`;

const NewAlbum = styled.h4`
  text-transform: uppercase;
  border-bottom: 0.1rem solid ${colors.light};
  color: ${colors.light};
  margin-bottom: 1.2rem;
`;

const MusicWrapper = styled.div`
  min-height: 100vh;
  padding: 8rem 0;
`;

const IndexPage = () => {
  const data = useStaticQuery(indexQuery);
  const {
    heroImage,
    seoMetaTags,
    featuredAlbum,
    additionalAlbums,
    liveDates,
    heroCtaLabel,
    heroCtaHeadline,
    heroCtaText,
    heroCtaButtonText,
    heroCtaButtonLink,
    // tracks,
  } = data.home;

  // const selectedTrack = tracks.length
  //   ? tracks[Math.floor(Math.random() * tracks.length)]
  //   : null;

  return (
    <Fragment>
      <SEO meta={seoMetaTags} />

      <HeroWrapper id="top">
        <HeroImg backgroundColor={colors.light} fluid={heroImage.fluid} />
        <HeroInnerContainer>
          <div>
            <NewAlbum>{heroCtaLabel}</NewAlbum>
            <h2>{heroCtaHeadline}</h2>
            <h3>{heroCtaText}</h3>
            <a
              href={heroCtaButtonLink}
              target="_blank"
              rel="noopener noreferrer"
            >
              <button>
                <span>{heroCtaButtonText}</span>
              </button>
            </a>
          </div>
        </HeroInnerContainer>
      </HeroWrapper>

      <HourglassWrapper>
        <Container>
          {/* {selectedTrack && <Audio source={selectedTrack.file.url} />} */}
          <Hourglass fluid={data.hourglass.childImageSharp.fluid} />
        </Container>
      </HourglassWrapper>

      <MusicWrapper>
        <FeaturedWrapper id="music">
          <Inner>
            <AlbumWrapper>
              <Heading title={'New album'} />
              <Album fluid={featuredAlbum.image.fluid} />
            </AlbumWrapper>

            <div className="content">
              <h2>{featuredAlbum.name}</h2>
              {featuredAlbum.optionalText && (
                <h3>{featuredAlbum.optionalText}</h3>
              )}
              <a
                target="blank"
                rel="nofollow noopener noreferrer"
                href={featuredAlbum.listenLink}
              >
                <button>
                  <span>
                    {featuredAlbum.buttonTextOverride || 'Listen now'}
                  </span>
                </button>
              </a>
            </div>
          </Inner>
        </FeaturedWrapper>

        <AdditionalWrapper>
          <Inner>
            {additionalAlbums.map(a => {
              return (
                <div className="add" key={a.id}>
                  <Album fluid={a.image.fluid} />
                  {/* <Link to="/store">
                    <button>Shop now</button>
                  </Link> */}
                  <a
                    target="blank"
                    rel="nofollow noopener noreferrer"
                    href={a.listenLink}
                  >
                    <button>
                      <span>Listen now</span>
                    </button>
                  </a>
                </div>
              );
            })}
          </Inner>
        </AdditionalWrapper>
      </MusicWrapper>

      <DateWrapper id="tour">
        <Inner>
          <Heading title={'Tour'} />
          {liveDates.map(l => {
            return <TourDate key={l.id} date={l} />;
          })}
        </Inner>
      </DateWrapper>

      <HourglassWrapper>
        <Container>
          <Hourglass fluid={data.hourglass.childImageSharp.fluid} />
        </Container>
      </HourglassWrapper>
    </Fragment>
  );
};

export default IndexPage;
