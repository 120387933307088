import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Moment from 'react-moment';

import { font, colors } from '../consts/style';
import mq from '../style/mq';

const Wrapper = styled.div`
  padding: 1.6rem 0;
  border-bottom: 0.1rem solid ${colors.gold};
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  .date-city {
    width: 30rem;
    flex-shrink: 0;
  }
  .btns {
    width: 100%;
    justify-content: flex-end;
  }
  .venue {
    flex-shrink: 0;
  }
  ${mq.tablet`
    flex-wrap: wrap;
    text-align: center; 
    .date-city p {
      margin-top: 1rem;
    }
    .btns, .venue ,.date-city, .moment {
      width: 100%;
      margin-left: 0 !important;
      text-align: center;
      justify-content: center;
      margin-bottom: 1.2rem;
    }
    .text {
      margin-bottom: 1.2rem;
    }
    .btns {
      justify-content: center;
    }
  `}
  .btns {
    display: flex;
    margin-left: 1.2rem;
  }
  button {
    ${font.button};
    margin-left: 0.4rem;
  }
  p {
    ${font.p};
    margin: 0;
    color: ${colors.goldTint};
  }
`;

const M = styled(Moment)`
  color: ${colors.goldTint};
  text-transform: uppercase;
  font-weight: bold;
  margin-bottom: 1.2rem;
`;

export default function TourDate({ date }) {
  const getDate = () => {
    let first = <M format={'ddd MMM D, YYYY'}>{date.date}</M>;
    if (!date.throughDate) return first;
    if (date.throughDate) {
      first = <M format={'MMM D'}>{date.date}</M>;
      const second = <M format={'MMM D, YYYY'}>{date.throughDate}</M>;
      return (
        <div className="moment">
          {first} - {second}
        </div>
      );
    }
  };
  return (
    <Wrapper>
      <div className="date-city">
        {getDate()}
        <p>{date.city}</p>
      </div>
      <div className="venue">
        <p>{date.venue}</p>
      </div>
      <div className="btns">
        {date.vipLink && !date.soldOut && (
          <a
            target="blank"
            rel="nofollow noopener noreferrer"
            href={date.vipLink}
          >
            <button>
              <span>VIP</span>
            </button>
          </a>
        )}
        {date.ticketLink && !date.soldOut && (
          <a
            target="blank"
            rel="nofollow noopener noreferrer"
            href={date.ticketLink}
          >
            <button>
              <span>Tickets</span>
            </button>
          </a>
        )}
        {date.soldOut && (
          <button className="disabled" disabled>
            <span>Sold out</span>
          </button>
        )}
      </div>
    </Wrapper>
  );
}

TourDate.propTypes = {
  date: PropTypes.object.isRequired,
};
